import React, { Component } from "react";
import styles from "./card.module.css";
import Table from "../table/BillingTable";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";

class Card extends Component {
  render() {
    const Crumbs = (breadcrumbs) => {
      breadcrumbs = useBreadcrumbs();
      console.log(breadcrumbs);
    };

    return (
      <div className={`${styles.stretch_card} ${styles.first_card}`}>
        <div className={`${styles.card}`}>
          <div className={`${styles.card_body}`}>
            <h4 className={`${styles.card_title}`}>{this.props.titleName}</h4>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
