import React, { Component, useEffect, useState } from "react";
import "./style.css";
import styles from "./table.module.css";
import BillingRecords from "./data.json";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import DownloadIcon from "../../images/download_rounded.svg";
import NotPaidIcon from "../../images/not_paid_icon.svg";
import PendingIcon from "../../images/carbon_pending.svg";
import PaidIcon from "../../images/paid_icon.svg";
import $ from "jquery";
import "datatables.net-responsive";
//For API Requests
import axios from "axios";
import { useParams } from "react-router-dom";
import { Fragment } from "react";

class AdminBillingTable extends React.Component {
    //Declare data store variables
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            cols: [],
            error: null,
            isLoaded: false,
            users: [],
            usersData: [],
            invoicesData: [],
            invoiceDated: this.props.params.date,
            userId: this.props.userId,
            storedUserId: this.props.storedUserId,
        };
    }
    componentDidMount() {
        const loggedId = this.props.userId;
        

        fetch(
            `https://billingconnapi.kgaswe.ac.bw/api/get-all-invoices`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        invoicesData: result,
                        userId: loggedId,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                    });
                }
            );

       

        //init Datatable
        setTimeout(() => {
           const table = $("#billingTable").DataTable({
                lengthMenu: [
                    [5, 10, 15, -1],
                    [5, 10, 15, "All"],
                ],
                language: {
                    paginate: {
                        next: "&#8594;", // or '→'
                        previous: "&#8592;", // or '←'
                    },
                },
                order: [[1, "asc"]],
                responsive: true,
                retrieve: true,
                paging: true,
                searching: true
            });
        }, 10000);
    }
    render() {
        const { error, isLoaded, users, usersData, invoicesData, userId } =
            this.state;

        //Datatable HTML
        return (
            <div className={`${styles.table_responsive}`}>
                <table 
                    id="billingTable"
                    class="table table-striped table-bordered table-sm row-border hover mb-3 mt-3" >
                    <thead>
                        <tr>
                            <th>Invoice #</th>
                            <th>Issue Date</th>
                            <th>Amount</th>
                            <th>Download Invoice</th>
                            <th>Status</th>
                            <th>Payment Received</th>
                            <th>Total (BWP)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoicesData.map((result) => {
                            const issue_date = dateFormat(
                                result.date_issued,
                                "mmmm dd, yyyy"
                            );
                            const invDated = result.date_issued;
                            const userId = this.props.userId;
                            // 👉️ Sat Oct 01 2022 ...

                            if (result.payment_status === 0) {
                                return (
                                    <tr class="table-success" key={result.id}>
                                        <td data-label="#">
                                            <Link
                                                to={`/invoices/${result.id}/${invDated}/${userId}`}
                                            >
                                                {result.id}
                                            </Link>
                                        </td>
                                        <td data-label="Issue Date">{issue_date}</td>
                                        <td data-label="Amount">{result.total_due}</td>
                                        <td data-label="Download">
                                            <Link
                                                to={`/invoices/${result.id}/${invDated}/${userId}`}
                                            >
                                                <img
                                                    src={DownloadIcon}
                                                    className={`${styles.icon_spacer}`}
                                                />
                                                Download
                                            </Link>
                                        </td>
                                        <td data-label="Status"
                                            className={`${styles.td_status} ${styles.not_paid}`}
                                        >
                                            <img
                                                src={NotPaidIcon}
                                                className={`${styles.icon_spacer}`}
                                            />
                                            Not Paid
                                        </td>
                                        <td data-label="Received"
                                            className={`${styles.td_status} ${styles.not_paid}`}
                                        >
                                            None
                                        </td>
                                        <td data-label="Total">{result.total_due.toFixed(2)}</td>
                                    </tr>
                                );
                            } else if (result.payment_status === 1) {
                                return (
                                    <tr class="table-success">
                                        <td data-label="#">{result.id}</td>
                                        <td data-label="Issue Date">{issue_date}</td>
                                        <td data-label="Amount">{result.total_due}</td>
                                        <td data-label="Download">
                                            <Link>
                                                <img
                                                    src={DownloadIcon}
                                                    className={`${styles.icon_spacer}`}
                                                />
                                                Download
                                            </Link>
                                        </td>
                                        <td data-label="Status"
                                            className={`${styles.td_status} ${styles.paid}`}
                                        >
                                            <img
                                                src={PaidIcon}
                                                className={`${styles.icon_spacer}  ${styles.icon_spacer_paid}`}
                                            />
                                            Paid
                                        </td>
                                        <td data-label="Received"
                                            className={`${styles.icon_spacer}  ${styles.icon_spacer_paid}`}
                                        >
                                            {result.payment_status}
                                        </td>
                                        <td data-label="Total">{result.total_due.toFixed(2)}</td>
                                    </tr>
                                );
                            } else if (result.payment_status === 2) {
                                return (
                                    <tr class="table-success">
                                        <td data-label="#">{result.id}</td>
                                        <td data-label="Issue Date">{issue_date}</td>
                                        <td data-label="Amount">{result.total_due}</td>
                                        <td data-label="Download">
                                            <Link>
                                                <img
                                                    src={DownloadIcon}
                                                    className={`${styles.icon_spacer}`}
                                                />
                                                Download
                                            </Link>
                                        </td>
                                        <td data-label="Status"
                                            className={`${styles.td_status} ${styles.pending}`}
                                        >
                                            <img
                                                src={PendingIcon}
                                                className={`${styles.icon_spacer}`}
                                            />
                                            Pending
                                        </td>
                                        <td data-label="Received"
                                            className={`${styles.td_status} ${styles.pending}`}
                                        >
                                            Pending
                                        </td>
                                        <td data-label="Total">{result.total_due.toFixed(2)}</td>
                                    </tr>
                                );
                            } else {
                                return (
                                    <tr class="table-success">
                                        <td>Loading..</td>
                                    </tr>
                                );
                            }
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}
export default (props) => <AdminBillingTable {...props} params={useParams()} />;
