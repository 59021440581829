import React, { Fragment, useState, useEffect} from "react";
import { BrowserRouter as Router, Routes, Route, Link, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import Navbar from "./components/header/Navbar";
import Sidebar from "./components/sidebar/Sidebar";
import Billing from "./pages/Billing";
import Login from "./pages/Login";
import LoginCp from "./pages/LoginCp";
import BillingTable ,  { allBillsLoader } from "./components/table/BillingTable";
import AdminBilling from "./pages/admin/AdminBilling";
import Invoice from "./components/invoices/Invoice";
import ClipLoader from "react-spinners/ClipLoader";
import Bills, { billsLoader, loggedUserLoader } from "./components/table/Bills";
import useSWR, { SWRConfig } from "swr";
import "./App.css";

const App = (props) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = React.useState([]);
  useEffect(()=>{
    const items = JSON.parse(localStorage.getItem('user_id'));
  if (items) {
   setItems(items);
   //console.log(items);
  }

    setLoading(true)
    setTimeout(()=>{
      setLoading(false)
    }, 3000)
  }, [])



  const fetcher = (...args) => fetch(...args).then(res => res.json());
  return (
    <div className="kis_container">
    {
      loading ? 
      (<div className="loaderStyle"><ClipLoader
      color={'#591B32'}
      loading={loading}
      size={100}
    /></div> )
    :
     (<div>

    {
      <Router>
        <Routes>
          <Route
            path="/billing"
            element={
              <Fragment>
                <Navbar />
              
                  <Sidebar/>
            
                
                <Billing />
                )
              </Fragment>
            }
          />
          <Route
          path="/admin/billing"
          element={
            <Fragment>
              <Navbar />
              
                <Sidebar/>
           
      
              <AdminBilling />
            </Fragment>
          }
        />
          <Route path="/invoices" element={<Invoice />} />
          <Route path="/invoices/:id/:date/:pid" element={<Invoice />} />
          <Route path="/" element={<Login/>}/>
          <Route path="/login" element={<LoginCp/>}/>
        </Routes>
      </Router>
    }
    </div>
     )
    }
 
    </div>
  );
};



export default App;
