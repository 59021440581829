import React, { Component, useEffect, useState } from "react";
import "./style.css";
import styles from "./table.module.css";
import BillingRecords from "./data.json";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import DownloadIcon from "../../images/download_rounded.svg";
import NotPaidIcon from "../../images/not_paid_icon.svg";
import PendingIcon from "../../images/carbon_pending.svg";
import PaidIcon from "../../images/paid_icon.svg";
import $ from "jquery";
import "datatables.net-responsive";
//For API Requests
import axios from "axios";
import { useParams } from "react-router-dom";
import { Fragment } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

class BillingTable extends React.Component {
    //Declare data store variables
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            cols: [],
            error: null,
            isLoaded: false,
            users: [],
            usersData: [],
            invoicesData: [],
            invoiceDated: this.props.params.date,
            userId: this.props.userId,
            storedUserId: this.props.storedUserId,
            loading: true,
            loginStatus:null,
        };
    }
    componentDidMount() {
        const loggedId = this.props.userId;
        axios.get("https://billing-server.kgaswe.ac.bw/login").then((response) => {
      if (response.data.loggedIn == true) {
        //setLoginStatus(response.data.user[0].id);
        this.setState({
          loginStatus: response.data.user[0].id,
        });
        console.log(this.state.loginStatus)
      }
    });
        fetch(`https://billing-server.kgaswe.ac.bw/login`)
            .then((res) => res.json())
            .then(
                (result) => {
                    const loggedId = this.state.loginStatus;
          console.log("fetch Login ID in Billing Table")
          console.log(this.state.loginStatus)
                    //const loggedId = result.users.id;

                    fetch(
                        `https://billingconnapi.kgaswe.ac.bw/api/get-invoices/${loggedId}`
                    )
                        .then((res) => res.json())
                        .then(
                            (result) => {
                                this.setState({
                                    isLoaded: true,
                                    invoicesData: result,
                                    userId: loggedId,
                                    loading: false,
                                });
                            },
                            // Note: it's important to handle errors here
                            // instead of a catch() block so that we don't swallow
                            // exceptions from actual bugs in components.
                            (error) => {
                                this.setState({
                                    isLoaded: true,
                                    error,
                                });
                            }
                        );

                    this.setState({
                        method: "GET",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        isLoaded: true,
                        users: result,
                        userId: loggedId,
                        loading: false,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                    });
                }
            );

        //init Datatable
        setTimeout(() => {
            const table = $("#billingTable").DataTable({
                lengthMenu: [
                    [5, 10, 15, -1],
                    [5, 10, 15, "All"],
                ],
                language: {
                    paginate: {
                        next: "&#8594;", // or '→'
                        previous: "&#8592;", // or '←'
                    },
                },
                order: [[1, "asc"]],
                responsive: true,
                retrieve: true,
                paging: false,
                bPaginate: false,
                searching: false,
            });
        }, 1000);
    }
    render() {
        const {
            error,
            isLoaded,
            users,
            usersData,
            invoicesData,
            userId,
            loading,
        } = this.state;

        //Datatable HTML
        return (
            <div>
                <table
                    id="billingTable"
                    className="table table-striped table-bordered table-sm row-border hover mb-3 mt-3"
                >
                    <thead>
                        <tr>
                            <th>Invoice #</th>

                            <th>Student Names</th>
                            <th>Issue Date</th>
                            <th>Download Invoice</th>
                            <th>Payment Status</th>
                            {/*    <th>Status</th>
                        <th>Payment Received</th> */}
                            <th>Total Due (BWP)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoicesData.map((result) => {
                            const issue_date = dateFormat(
                                result.date_issued,
                                "mmmm dd, yyyy"
                            );
                            const invDated = result.date_issued;
                            const userId = this.props.userId;
                            // 👉️ Sat Oct 01 2022 ...

                            if (result.total_due > 0) {
                                return (
                                    <tr
                                        className="table-success"
                                        key={result.id}
                                    >
                                        <td data-label="#">
                                            <Link
                                                to={`/invoices/${result.id}/${invDated}/${userId}`}
                                            >
                                                {result.id}
                                            </Link>
                                        </td>
                                        <td data-label="Student">
                                            {result.std_firstname}{" "}
                                            {result.std_middlename}{" "}
                                            {result.std_lastname}
                                        </td>
                                        <td data-label="Issue Date">
                                            {issue_date}
                                        </td>

                                        <td data-label="Download">
                                            <Link
                                                to={`/invoices/${result.id}/${invDated}/${userId}`}
                                            >
                                                <img
                                                    src={DownloadIcon}
                                                    className={`${styles.icon_spacer}`}
                                                />
                                                Download
                                            </Link>
                                        </td>
                                        <td
                                            data-label="Status"
                                            className={`${styles.td_status} ${styles.not_paid}`}
                                        >
                                            <img
                                                src={NotPaidIcon}
                                                className={`${styles.icon_spacer}`}
                                            />
                                            Not Paid
                                        </td>
                                        {/* <td data-label="Status"
                                        className={`${styles.td_status} ${styles.not_paid}`}
                                    >
                                        <img
                                            src={NotPaidIcon}
                                            className={`${styles.icon_spacer}`}
                                        />
                                        Not Paid
                                    </td>
                                    <td data-label="Received"
                                        className={`${styles.td_status} ${styles.not_paid}`}
                                    >
                                        None
                                    </td> */}
                                        <td
                                            data-label="Total"
                                            className={`total-due ${
                                                result.total_due > 0
                                                    ? "total_danger"
                                                    : "total_success"
                                            }`}
                                        >
                                            {result.total_due.toFixed(2)}
                                        </td>
                                    </tr>
                                );
                            } else if (result.total_due <= 0) {
                                return (
                                    <tr
                                        className="table-success"
                                        key={result.id}
                                    >
                                        <td data-label="#">
                                            <Link
                                                to={`/invoices/${result.id}/${invDated}/${userId}`}
                                            >
                                                {result.id}
                                            </Link>
                                        </td>
                                        <td data-label="Student">
                                            {result.std_firstname}{" "}
                                            {result.std_middlename}{" "}
                                            {result.std_lastname}
                                        </td>
                                        <td data-label="Issue Date">
                                            {issue_date}
                                        </td>

                                        <td data-label="Download">
                                            <Link
                                                to={`/invoices/${result.id}/${invDated}/${userId}`}
                                            >
                                                <img
                                                    src={DownloadIcon}
                                                    className={`${styles.icon_spacer}`}
                                                />
                                                Download
                                            </Link>
                                        </td>
                                        <td
                                            data-label="Status"
                                            className={`${styles.td_status} ${styles.paid}`}
                                        >
                                            <img
                                                src={PaidIcon}
                                                className={`${styles.icon_spacer}  ${styles.icon_spacer_paid}`}
                                            />
                                            Paid
                                        </td>
                                        {/*     <td data-label="Status"
                                        className={`${styles.td_status} ${styles.paid}`}
                                    >
                                        <img
                                            src={PaidIcon}
                                            className={`${styles.icon_spacer}  ${styles.icon_spacer_paid}`}
                                        />
                                        Paid
                                    </td>
                                    <td data-label="Received"
                                        className={`${styles.icon_spacer}  ${styles.icon_spacer_paid}`}
                                    >
                                        {result.payment_status}
                                    </td> */}
                                        <td
                                            data-label="Total"
                                            className={`total-due ${
                                                result.total_due > 0
                                                    ? "total_danger"
                                                    : "total_success"
                                            }`}
                                        >
                                            {result.total_due.toFixed(2)}
                                        </td>
                                    </tr>
                                );
                            } else {
                                return (
                                    <tr className="table-success">
                                        <td>Loading..</td>
                                    </tr>
                                );
                            }
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}
export default (props) => <BillingTable {...props} params={useParams()} />;
