import React, { Component } from "react";
import { render } from "react-dom";
import styles from "./invoice_table.module.css";
import { useParams } from "react-router-dom";

export class InvoiceTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoiceIdentifier: this.props.invoiceIdentifier,
            invoiceParentId: this.props.invoiceParentId,
        };
    }

    render() {
        const { error, isLoaded, invoiceIdentifier, invoiceParentId } =
            this.state;

        const tableBody = this.props.invoicesData.map((item) => (
            <tr key={item.id}>
                <td data-label="#">{item.id}</td>
                <td data-label="Description">{item.pastel_code}-School Fees</td>
                <td data-label="Quantity">1</td>
                <td data-label="Unit Price">BWP {item.total_due.toFixed(2)}</td>
                <td data-label="Total">BWP {item.total_due.toFixed(2)}</td>
            </tr>
        ));
        const total = this.props.invoicesData.reduce(
            (total, currentItem) => (total = total + currentItem.total_due),
            0
        );
        return (
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Item #</th>
                            <th>Item Description</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Total Amount</th>
                        </tr>
                    </thead>
                    <tbody>{tableBody}</tbody>

                    <tfoot>
                        <tr>
                            <td
                                colSpan="3"
                                className={`${styles.table_no_border}`}
                            >
                                Please inform us if you find any discrepancies
                                in this invoice{" "}
                            </td>
                            <td
                                className={`${styles.table_total} ${styles.bold_weight}`}
                            >
                                Total Payment
                            </td>
                            <td
                                className={`${styles.table_total} ${styles.bold_weight}`}
                            >
                                BWP {total.toFixed(2)}
                            </td>
                            {/*   <td className={`${styles.bold_weight}`}>Sub Total</td>
              <td>BWP 35.95</td> */}
                        </tr>
                        {/*  <tr>
              <td className={`${styles.bold_weight}`}>Charges</td>
              <td>BWP 35.95</td>
            </tr>
            <tr>
              <td className={`${styles.bold_weight}`}>- Discount</td>
              <td>BWP 100.50</td>
            </tr> */}

                        {/* <tr className={`${styles.table_total} ${styles.bold_weight}`}>
              <td>Total Payment</td>
              <td>BWP {total.toFixed(2)}</td>
            </tr> */}
                    </tfoot>
                </table>
            </div>
        );
    }
}

export default (props) => <InvoiceTable {...props} params={useParams()} />;
