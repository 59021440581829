import React from "react";
import { Link } from "react-router-dom";
import styles from "./footer.module.css";
import "font-awesome/css/font-awesome.min.css";
import "./footer.css";
import MainContent from "../main_content/MainContent";

const Footer = () => {
  return (
    <footer className="footer text text-dark">
      <div className="d-sm-flex justify-content-center justify-content-sm-between">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
          <p>
            {" "}
            &copy; {new Date().getFullYear()} Kgaswe International School All
            Rights Reserved
          </p>
        </span>
        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
          Designed and Developed By&nbsp;
          <a href="https://ntebogangtechnologies.co.bw" target="_blank">
            Ntebogang Technologies
          </a>
        </span>
      </div>
    </footer>
  );
};
export default Footer;
