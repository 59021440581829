import React, { Component, Fragment } from "react";
import styles from "./invoice.module.css";
import FullLogo from "../../../src/images/Kgaswe_logo_web.png";
import Card from "../card/Card";
import InvoiceTable from "./InvoiceTable";
import ReactToPrint from "react-to-print";
import ReactToPdf from "react-to-pdf";
import { useParams } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import axios from "axios";
import dateFormat from "dateformat";
export class Invoice extends Component {
    //Declare data store variables
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            cols: [],
            error: null,
            isLoaded: false,
            users: [],
            usersData: [],
            invoiceData: [],
            invoicesData: [],
            getStudent: [],
            invoiceIdentifier: this.props.params.id,
            invoiceDated: this.props.params.date,
            userId: 0,
        };
    }
    componentDidMount() {
        const loggedId = this.props.params.pid;
        fetch(`https://billingconnapi.kgaswe.ac.bw/api/get-parents/${loggedId}`)
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        invoiceData: result,
                        userId: loggedId,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                    });
                }
            );

        fetch(
            `https://billingconnapi.kgaswe.ac.bw/api/single-invoice/${this.props.params.id}`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        invoicesData: result,
                        invoiceIdentifier: this.props.params.id,
                        invoiceDated: this.props.params.date,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                    });
                }
            );

        fetch(
            `https://billingconnapi.kgaswe.ac.bw/api/get-student/${this.props.params.id}`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        getStudent: result,
                        invoiceIdentifier: this.props.params.id,
                        invoiceDated: this.props.params.date,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                    });
                }
            );
    }

    render() {
        const {
            error,
            isLoaded,
            invoiceData,
            invoicesData,
            invoiceIdentifier,
            invoiceDated,
            getStudent,
            userId,
        } = this.state;

        const studentName = getStudent.map((resData, i) => (
            <Fragment key={i}>
                {resData.std_firstname} {resData.std_middlename} {resData.std_lastname}
            </Fragment>
        ));

        const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "July",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const invoiceIssueDate = new Date(invoiceDated);
        const fromDate = new Date(invoiceDated);
        const date =
            invoiceIssueDate.getDate() +
            "-" +
            invoiceIssueDate[fromDate.getMonth()] +
            "-" +
            invoiceIssueDate.getFullYear();
        const invoiceFromDate = new Date(
            invoiceIssueDate.getFullYear(),
            invoiceIssueDate.getMonth(),
            1
        );
        const invoiceToDate = new Date(
            invoiceIssueDate.getFullYear(),
            invoiceIssueDate.getMonth() + 1,
            0
        );

        const dateResolver = new Date(invoiceFromDate);

        const firstDay = new Date(
            dateResolver.getFullYear(),
            dateResolver.getMonth(),
            1
        );
        const convertedFirstDay = dateFormat(firstDay, "mmmm dd, yyyy");
        // 👉️ Sat Oct 01 2022 ...

        const lastDay = new Date(
            dateResolver.getFullYear(),
            dateResolver.getMonth() + 1,
            0
        );
        const convertedLastDay = dateFormat(lastDay, "mmmm dd, yyyy");
        // 👉️ Mon Oct 31 2022 ...

        const dueDate = new Date(convertedLastDay);

        // it adds 15 days to  the end of invoice period
        dueDate.setDate(dueDate.getDate() + 15);
        const addedDays = dueDate.toDateString();
        const convertedDueDate = dateFormat(addedDays, "mmmm dd, yyyy");

        const ref = React.createRef();
        const options = {
            orientation: "portrait",
            unit: "in",
            format: [14, 14],
        };

        const pageStyle = `{ size: 2.5in 2in }`;
        return (
            <div className={`${styles.invoice_hold_size}`}>
                <div className={`${styles.print_button_holder}`}>
                    <ReactToPdf
                        targetRef={this.componentRef}
                        filename={
                            "Invoice_" +
                            invoiceIdentifier +
                            "_" +
                            invoiceDated +
                            ".pdf"
                        }
                        options={options}
                        x={0}
                        y={0}
                        scale={1}
                    >
                        {({ toPdf }) => (
                            <button
                                className={`${styles.print_button}`}
                                onClick={toPdf}
                            >
                                Download Invoice
                            </button>
                        )}
                    </ReactToPdf>

                    {
                        <ReactToPrint
                            pageStyle={pageStyle}
                            trigger={() => {
                                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                // to the root node of the returned component as it will be overwritten.
                                return (
                                    <button
                                        className={`${styles.print_button}`}
                                    >
                                        Print Invoice
                                    </button>
                                );
                            }}
                            content={() => this.componentRef}
                        />
                    }
                    <Link className={`${styles.print_button}`} to="/billing">
                        Go Back
                    </Link>
                </div>
                {/*ref={ el => (this.componentRef = el) } */}
                <div
                    className={`${styles.invoice_main_container}`}
                    ref={(el) => (this.componentRef = el)}
                >
                    {this.props.children}
                    <div className={`${styles.invoice_top_bar}`}>
                        <h2 className={`${styles.invoice_top_title}`}>
                            INVOICE
                        </h2>
                        <p className={`${styles.invoice_top_text}`}>
                            Academic Centre of Excellence
                        </p>
                    </div>
                    <div className={`${styles.emblem_card}`}>
                        <div className={`${styles.emblem_card_logo}`}>
                            <img src={FullLogo} className={styles.full_logo} />
                        </div>
                        <div className={`${styles.emblem_card_content}`}>
                            <h1 className={styles.emblem_content}>
                                Kgaswe International School
                            </h1>
                            <p className={styles.emblem_location}>
                                Plot 605, Extension 4, Palapye, Botswana Private
                                Bag 75
                            </p>
                            <p className={styles.emblem_contacts}>
                                (T) (+267) 492-2180 (F) 4922181, e-mail:
                                highschool@kgaswe.ac.bw
                            </p>
                        </div>
                    </div>

                    <div className={`${styles.invoice_holder}`}>
                        <Card>
                            <div className={`${styles.invoice_intro_card}`}>
                                <h4 className={`${styles.intro_title}`}>
                                    INVOICE # :{" "}
                                </h4>
                                <h4 className={`${styles.intro_inv_number}`}>
                                    {invoiceIdentifier}
                                </h4>
                                <p className={`${styles.intro_inv_helper}`}>
                                    Please be reminded that late payment shall
                                    be subject to penalties as agreed by both
                                    parties prior to enrollment. Therefore,
                                    kindly pay the amount payable within the
                                    allocated time. Thank you for choosing
                                    Kgaswe International School
                                </p>
                            </div>
                        </Card>
                        {invoiceData.map((resData, i) => (
                            <div key={i}>
                                <Card className={`${styles.no_card_title}`}>
                                    <div className={`${styles.col_2}`}>
                                        <div className={`${styles.col}`}>
                                            <h3
                                                className={`${styles.invoice_card_title}`}
                                            >
                                                Client Details
                                            </h3>
                                            <p>This innvoice is sent to:</p>
                                            <p>
                                                <span
                                                    className={`${styles.text_bold}`}
                                                >
                                                    Name :{" "}
                                                </span>
                                                {resData.firstname}{" "}
                                                {resData.middlename}
                                                {resData.lastname}
                                            </p>
                                            <p>
                                                <span
                                                    className={`${styles.text_bold}`}
                                                >
                                                    Student Name :{" "}
                                                </span>
                                                {studentName}
                                                {/*resData.std_firstname*/}{" "}
                                                {/*resData.std_lastname*/}
                                            </p>
                                            <p>
                                                <span
                                                    className={`${styles.text_bold}`}
                                                >
                                                    Address :{" "}
                                                </span>
                                                {resData.address}
                                            </p>
                                        </div>
                                        <div className={`${styles.col}`}>
                                            <h3
                                                className={`${styles.invoice_card_title}`}
                                            >
                                                Invoice Details
                                            </h3>
                                            <p>
                                                <span
                                                    className={`${styles.text_bold}`}
                                                >
                                                    From :{" "}
                                                </span>{" "}
                                                {convertedFirstDay}
                                            </p>
                                            <p>
                                                <span
                                                    className={`${styles.text_bold}`}
                                                >
                                                    To :{" "}
                                                </span>
                                                {convertedLastDay}
                                            </p>
                                            {/*   <p>
                                                <span
                                                    className={`${styles.text_bold}`}
                                                >
                                                    Payment Method :{" "}
                                                </span>
                                                Cash
                                            </p> */}
                                            <p>
                                                <span
                                                    className={`${styles.text_bold}`}
                                                >
                                                    Due Date :{" "}
                                                </span>
                                                {convertedDueDate}
                                            </p>
                                        </div>
                                    </div>
                                </Card>
                                <Card titleName="Invoice Summary">
                                    <InvoiceTable
                                        invoicesData={invoicesData}
                                        invoiceIdentifier={invoiceIdentifier}
                                    />
                                </Card>
                            </div>
                        ))}
                        <Card className={`${styles.no_card_title}`}>
                            <div className={`${styles.col_2}`}>
                                <div className={`${styles.col}`}>
                                    <h3
                                        className={`${styles.invoice_card_title}`}
                                    >
                                        Payment Details
                                    </h3>
                                    <p></p>
                                    <p>
                                        <span className={`${styles.text_bold}`}>
                                            Account Name :{" "}
                                        </span>
                                        Kgaswe International School
                                    </p>
                                    <p>
                                        <span className={`${styles.text_bold}`}>
                                            Bank Name :{" "}
                                        </span>
                                        FNB
                                    </p>
                                    <p>
                                        <span className={`${styles.text_bold}`}>
                                            Branch :{" "}
                                        </span>
                                        Palapye
                                    </p>
                                    <p>
                                        <span className={`${styles.text_bold}`}>
                                            Branch Code :{" "}
                                        </span>
                                        28 31 67
                                    </p>
                                </div>
                                <div className={`${styles.col}`}>
                                    <h3
                                        className={`${styles.invoice_card_title} ${styles.invoice_card_title_hidden}`}
                                    >
                                        Payment Details
                                    </h3>
                                    <p>
                                        <span className={`${styles.text_bold}`}>
                                            Account Number :{" "}
                                        </span>{" "}
                                        621 704 318 12
                                    </p>
                                    <p>
                                        <span className={`${styles.text_bold}`}>
                                            Ref No :{" "}
                                        </span>
                                        Child's surname or School reference
                                        Number
                                    </p>
                                    <p>
                                        <span className={`${styles.text_bold}`}>
                                            SWIFT :{" "}
                                        </span>
                                        FIRNBWGX ( Applicable for International
                                        Transfers)
                                    </p>
                                </div>
                            </div>
                            <p className={`${styles.payment_helper}`}>
                                After Deposit/Transfer please FAX proof of
                                payment to <a href="fax:4922181">4922181</a> or
                                email{" "}
                                <a href="mailto:finance@kgaswe.ac.bw">
                                    finance@kgaswe.ac.bw
                                </a>
                            </p>
                        </Card>
                    </div>

                    <footer>
                        <h6>
                            Payment is due{" "}
                            <span className={`${styles.payment_due}`}>
                                15 Days
                            </span>{" "}
                            from date of invoice
                        </h6>
                        <hr />
                        <p className={`${styles.kgaswe_name}`}>
                            Kgaswe International School
                        </p>
                        <p>
                            The Very Best in Pre-Primary, Primary and Secondary
                            Education
                        </p>
                    </footer>
                </div>
            </div>
        );
    }
}

export default (props) => <Invoice {...props} params={useParams()} />;
