import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import validation from './Validation';
import Axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import Validation from "./Validation";


export default function Login() {
  let navigate = useNavigate();
  const [usernameReg, setUsernameReg] = useState("");
  const [passwordReg, setPasswordReg] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setError] = useState({});

  const [loginStatus, setLoginStatus] = useState("");

  Axios.defaults.withCredentials = true;

  const register = () => {
    Axios.post("https://billing-server.kgaswe.ac.bw/register", {
      username: usernameReg,
      password: passwordReg,
    }).then((response) => {
      console.log(response);
    });
  };

  const login = () => {
    Axios.post("https://billing-server.kgaswe.ac.bw/login", {
      username: username,
      password: password,
    }).then((response) => {
      if (response.data.message) {

        setLoginStatus(response.data.message);
        <submitError />

      } else {

        setLoginStatus(response.data[0].id);
        return navigate("https://billing.kgaswe.ac.bw/billing");
      }
    });
  };



  const submitError = () => {
    return (
      <div>
        {loginStatus ? (
          <div className="alert alert-danger text text-white align-items-center bg-red">
            <p>{loginStatus}</p>
          </div>) : (<div>No Error</div>)
        }
      </div>
    )

  }




  const handleSubmit = (e) => {
    e.preventDefault();
    setError(validation(username, password));
  }





  useEffect(() => {

    // if(Object.keys(errors).length === 0 && (username !== "" && password !== "")){
    //   alert("No Errors");

    // }
    Axios.get("https://billing-server.kgaswe.ac.bw/login").then((response) => {
      if (response.data.loggedIn == true) {
        setLoginStatus(response.data.user[0].id);
        //navigate("/dashboard");
        //Redirect or Navigate to Billing Page if session is available
        return navigate("/billing");
        //window.location.replace('http://localhost:3000/billing');
      }
      else {
        //Redirect or Navigate to Login Page if session is not available
        return navigate("/");
      }
    });
  }, []);

  return (
    <div className="Login">
      <section>
        <header className="introducer-header">
          <div className="container-fluid introducer-division" >
            <h2 className="text text-white text-center font-weight-bold">Kgaswe International </h2>
            <h3 className="text text-white text-center font-weight-bold">The very best in Pre-Primary,Primary and Secondary Education </h3>
          </div>
        </header>
      </section>

      <section className="margin-top-50">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-12" tyle="padding:0px;">
                  <div className="card">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text text-center p-5">
                        <h1 className="text text-primary font-weight-bold">Hello!</h1>
                        <h6 className="text text-info font-weight-bold"> lets get started</h6>
                        <div className="brand-logo">
                          <img src="https://reporting.kgaswe.ac.bw/images/Kgaswe-International-School-Emblem-Logo@2x.webp" alt="logo" className="img-fluid" />
                        </div>
                        <h4 className="text text-primary">Kgaswe International School</h4>
                        <h3 className="text text-primary">Online Billing System</h3>
                        <div className="d-flex justify-content-around">
                          <a href="https://kgaswe.ac.bw" className="mt-2 btn btn-primary" target="_blank">Back to the Website</a>
                          <a href="https://kgaswe.ac.bw" className="mt-2 btn btn-primary" target="_blank">Reporting System</a>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-5 form-divider" >




                        <h4 className="text text-white font-weight-bold text-center mb-4">Sign in to continue</h4>

                        <div>
                          {loginStatus && Object.keys(errors).length === 0 ? (
                            <div className="alert alert-danger text text-white align-items-center bg-red">
                              <p className="m-0">{loginStatus}</p>

                            </div>) : (<div></div>)
                          }
                        </div>

                        <form className="pt-3 _form" id="_form" onSubmit={handleSubmit}>
                          <div className="form-group">
                            <input name="email" type="email" className="form-control form-control-lg bg-white" id="email" placeholder="email" onChange={(e) => {
                              setUsername(e.target.value);
                            }} />
                          </div>
                      
                          {errors.username && <p className="validation-errors">{errors.username}</p>}
                        <div className="form-group">

                          <input name="password" type="password" className="form-control form-control-lg bg-white" id="password" placeholder="password" onChange={(e) => {
                            setPassword(e.target.value);
                          }} />
                        </div>
                        {errors.password && <p className="validation-errors">{errors.password}</p>}


                        <div className="d-flex justify-content-start buttons-margin">
                          <a href="https://reporting.kgaswe.ac.bw/password/reset" className="auth-link text-white text-center btn padding-df ">Forgot password?</a>
                          <button className="btn btn-primary rounded font-weight-medium auth-form-btn" type="submit" onClick={login}>SIGN IN</button>
                        </div>


                      </form>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div></section >
    <section className="margin-top-50">
        <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block"> © 2023  Kgaswe International School. All rights reserved.</span>
                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Designed and Developed By <a href="https://ntebogangtechnologie.co.bw" target="_blank">Ntebogang Technologies</a></span>
            </div>
        </footer>
    </section>

    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js" integrity="sha384-w76AqPfDkMBDXo30jS1Sgez6pr3x5MlQ1ZAGC+nuZB+EYdgRZgiwxhTBTkF7CXvN" crossorigin="anonymous"></script>
      
    </div >
  );
}
