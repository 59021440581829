import React from "react";
import { Link } from "react-router-dom";
import styles from "./navbar.module.css";
import "font-awesome/css/font-awesome.min.css";
import "./navbar.css";
import "./responsive.scss";
import Logo from "../../../src/images/Kgaswe-International-School-Emblem-Logo@2x.webp";
import ProfileDropdown from "../dropdown/ProfileDropdown";
import { Fragment } from "react";
import MobileMenu from "../mobile_menu/MobileMenu";
import Media from 'react-media';

const Navbar = () => {
  return (
    <nav className={`${styles.navbar} ${styles.fixed_top}`}>
      <div className={styles.navbar_brand_wrapper}>
      <a href='/my-student' className={styles.logo_link}>
    
        <img src={Logo} className={styles.logo} />
        </a>
      </div>
      <div className={styles.navbar_menu_wrapper}>
        {" "}
        <h3>Kgaswe International School</h3>
      </div>




      
      <ul className="nav-links profile-links">
      
        <a id="pd" className="nav-link dropdown-toggle border-0">
        <ProfileDropdown/>
        </a>
      </ul> 
      <ul className="nav-links mobile-menu-links">
      
      <a id="pd" className="nav-link dropdown-toggle border-0">
      <MobileMenu/>
      </a>
    </ul> 
      
    </nav>
  );
};

export default Navbar;
