import React, { Component } from "react";
import axios from "axios";
import MainContent from "../components/main_content/MainContent";
import Card from "../components/card/Card";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import BillingTable from "../components/table/BillingTable";
import styles from "./billing.module.css";
import BillingRecords from "../data/data.json";
import dateFormat from "dateformat";
import { useParams } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Invoice from "../components/invoices/Invoice";
/*import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";*/
import $ from "jquery";
import { Fragment } from "react";
import { Navigate ,useNavigate } from "react-router-dom";
class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      users: [],
      usersData: [],
      totalDue: [],
      invoicesData: [],
      userId: this.props.userId,
      loading: true,
      loginStatus: null,
    };
    const userGetId = this.props.userId;
  }

  componentDidMount() {
    const id = 0;
    const userGetId = this.props.userId;

    axios.get("api/logged").then((response) => {});
    axios.get("https://billing-server.kgaswe.ac.bw/login").then((response) => {
        if (response.data.loggedIn == true) {
          //setLoginStatus(response.data.user[0].id);
          this.setState({
            loginStatus: response.data.user[0].id,
          });
          console.log(this.state.loginStatus)
        }
        else{
            window.location.replace('https://billing.kgaswe.ac.bw')
         }
      });
    fetch(`https://billing-server.kgaswe.ac.bw/login`)
      .then((res) => res.json())
      .then(
        (result) => {
            
          const loggedId = this.state.loginStatus;
          console.log("fetch Login ID")
          console.log(this.state.loginStatus)
          //
          fetch(`https://billingconnapi.kgaswe.ac.bw/api/get/${loggedId}`)
            .then((res) => res.json())
            .then(
              (result) => {
                console.log("fetch In Login")
                console.log(this.state.loginStatus)
                fetch(
                  `https://billingconnapi.kgaswe.ac.bw/api/get-total/${loggedId}`
                )
                  .then((res) => res.json())
                  .then(
                    (result) => {
                      this.setState({
                        isLoaded: true,
                        totalDue: result,
                        userId: loggedId,
                        loading: false,
                        mode: "no-cors",
                      });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                      this.setState({
                        isLoaded: true,
                        error,
                      });
                    }
                  );

                this.setState({
                  isLoaded: true,
                  usersData: result,
                  userId: loggedId,
                });
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                this.setState({
                  isLoaded: true,
                  error,
                });
              }
            );

          this.setState({
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            isLoaded: true,
            users: result,
            userId: loggedId,
          });
        },

        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  render() {
    const {
      error,
      isLoaded,
      users,
      usersData,
      invoicesData,
      id,
      userId,
      totalDue,
      loading,loginStatus
    } = this.state;
    const storedUserId = userId;
    const now = new Date();
    axios.defaults.withCredentials = true;
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const convertFirstDay = dateFormat(firstDay, "mmmm dd, yyyy");
    // ðŸ‘‰ï¸ Sat Oct 01 2022 ...

    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const convertLastDay = dateFormat(lastDay, "mmmm dd, yyyy");

    const estimatedTotal = totalDue.reduce(
      (total, currentItem) => (total = total + currentItem.total_due),
      0
    );

    // ðŸ‘‰ï¸ Mon Oct 31 2022 ...

    return (
        <div>

            <MainContent>
            <Card titleName="Billing Invoice">
              <Breadcrumbs />
            </Card>
            <div>
              {usersData.map((billData, i) => (
                <Card className={`${styles.no_card_title}`} key={i}>
                  <div className={`${styles.col_3}`}>
                    <div className={`${styles.col}`}>
                      <h3 className={`${styles.billing_card_title}`}>Account ID</h3>
                      <p>{billData.user_id}</p>
                    </div>
                    <div className={`${styles.col}`}>
                      <h3 className={`${styles.billing_card_title}`}>
                        Billing Period
                      </h3>
                      <p>
                        {convertFirstDay} - {convertLastDay}
                      </p>
                    </div>
                    <div className={`${styles.col}`}>
                      <h3 className={`${styles.billing_card_title}`}>
                        Estimated Payment
                      </h3>
                      <p>BWP {estimatedTotal.toFixed(2)}</p>
                    </div>
                  </div>
                </Card>
              ))}
              <Card titleName="Student Invoice">
                <BillingTable userId={userId} storedUserId={storedUserId} />
    
                <p className={`${styles.text_center}`}>
                  The Very Best in Pre-Primary. Primary and Secondary Education
                </p>
              </Card>
              <div></div>
            </div>
            <Footer />
          </MainContent>

       
        </div>
          
        
    );
  }
}

const fetchData = () => {

    axios.get("https://billing-server.kgaswe.ac.bw/login").then((response) => {
        if (response.data.loggedIn == true) {
          //setLoginStatus(response.data.user[0].id);
          this.setState({
            loginStatus: response.data.user[0].id,
          });
          console.log(this.state.loginStatus)
        }
        else{
            window.location.replace('https://billing.kgaswe.ac.bw/')
         }
      });
};

export default (props) => <Billing {...props} params={useParams()} />;
