import './mobilemenu.css';

import React, {useState, useEffect, useRef} from 'react';

function MobileMenu() {

  const [open, setOpen] = useState(false);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e)=>{
      if(!menuRef.current.contains(e.target)){
        setOpen(false);
        //console.log(menuRef.current);
      }      
    };

    document.addEventListener("mousedown", handler);
    

    return() =>{
      document.removeEventListener("mousedown", handler);
    }

  });

  return (
    <div className='mobile-menu menu-container' ref={menuRef}>
        <div className='menu-trigger' onClick={()=>{setOpen(!open)}}>
        <i className="fa fa-bars" aria-hidden="true"></i>

        </div>

        <div className={`dropdown-menu ${open? 'active' : 'inactive'}`} >
          <ul>
            <DropdownItem  text = {"Reporting System"}/>
            <DropdownItem  text = {"Billing"}/>
            <DropdownItem  text = {"Support"}/>
          </ul>
        </div>
      </div>
  );
}

function DropdownItem(props){
  return(
    <li className = 'dropdownItem'>
      <a> {props.text} </a>
    </li>
  );
}

export default MobileMenu;