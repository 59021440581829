import './dropdown.css';
import Cookie from 'js-cookie';
import React, {useState, useEffect, useRef} from 'react';

const logout = () => {
  Cookie.remove('userId')
  
}

function ProfileDropdown() {

  const [open, setOpen] = useState(false);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e)=>{
      if(!menuRef.current.contains(e.target)){
        setOpen(false);
        //console.log(menuRef.current);
      }      
    };

    document.addEventListener("mousedown", handler);
    

    return() =>{
      document.removeEventListener("mousedown", handler);
    }

  });

  return (
    <div className='profile-menu menu-container' ref={menuRef}>
        <div className='menu-trigger' onClick={()=>{setOpen(!open)}}>
        <i className="user fa fa-user-circle"></i>
        </div>

        <div className={`dropdown-menu ${open? 'active' : 'inactive'}`} >
          <ul>
            <DropdownItem  text = {"Reporting System"} link = {"/my-student"}/>
            <DropdownItem  text = {"Logout"}  onClick={logout}/>
            <a type='submit' onClick={logout}>Lou</a>
          </ul>
        </div>
      </div>
  );
}

function DropdownItem(props){
  return(
    <li className = 'dropdownItem'>
      <a href={props.link}> {props.text} </a>
    </li>
  );
}

export default ProfileDropdown;