import { Link, useLocation } from "react-router-dom";
import "./breadcrumbs.css";
import React, { Component } from "react";
function Breadcrumbs() {
  const location = useLocation();
  return (
    <nav className="no-shadow">
      <Link
        to="/"
        className={
          location.pathname === "/"
            ? "breadcrumb-active"
            : "breadcrumb-not-active"
        }
      >
        Home
      </Link>
      <span className="breadcrumb-slash">/</span>
      <Link
        to="/billing"
        className={
          location.pathname.startsWith("/billing")
            ? "breadcrumb-active"
            : "breadcrumb-not-active"
        }
      >
        Billing
      </Link>
    </nav>
  );
}
export default Breadcrumbs;
