import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import styles from "./sidebar.module.css";
import * as Icon from "react-bootstrap-icons";
import StudentIcon from "../../images/student_outline.svg";
import BillingIcon from "../../images/invoice_dollar.svg";
import SupportIcon from "../../images/bx_support.svg";
import StudentRecord from "./students.json";
class Sidebar extends Component {
  constructor(props) {
      super(props);
      this.state = {
          error: null,
          isLoaded: false,
          users: [],
          myStudents: [],
          totalDue: [],
          invoicesData: [],
          userId: this.props.userId,
      };
      const userGetId = this.props.userId;
  }

  componentDidMount() {
  fetch(`api/logged-in-users`)
  .then((res) => res.json())
  .then(
      (result) => {
          const loggedId = result.users.id;

          //
          fetch(
              `https://billingconnapi.kgaswe.ac.bw/api/get-students/${loggedId}`
          )
              .then((res) => res.json())
              .then(
                  (result) => {
                      //console.log(result);
                      this.setState({
                        isLoaded: true,
                        myStudents: result,
                        userId: loggedId,
                        mode: "no-cors",
                    });
                     
                  },
                  // Note: it's important to handle errors here
                  // instead of a catch() block so that we don't swallow
                  // exceptions from actual bugs in components.
                  (error) => {
                      this.setState({
                          isLoaded: true,
                          error,
                      });
                  }
              );

          this.setState({
              method: "GET",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
              },
              isLoaded: true,
              userId: loggedId,
          });
      },

      (error) => {
          this.setState({
              isLoaded: true,
              error,
          });
      }
  );
    }
    render(){
      const {
        error,
        isLoaded,
        myStudents,
        userId,
 
    } = this.state;
      return (
        <nav className={styles.sidebar}>
          <div className={`${styles.sidebar_content}`}>
            <ul className={`${styles.nav} ${styles.shadow}`}>
{              <li className={`${styles.nav_item} ${styles.border_bottom}`}>
                <a
                  href="/my-student"
                  className={`${styles.nav_link}`}
                >
                  <img src={StudentIcon} className={`${styles.icon_spacer}`} />
                  Reporting System
                </a>
              </li> }
              {/*myStudents.map((rowData, i) => (
                <li className={`${styles.nav_item} ${styles.border_bottom}`} key={i}>
                  <a href="/my-student" className={`${styles.nav_link}`}>
                    <img src={StudentIcon} className={`${styles.icon_spacer}`} />
                    {rowData.std_firstname}{" "}{rowData.std_lastname}
                  </a>
                </li>
              ))*/}
              <li className={`${styles.nav_item} ${styles.border_bottom}`}>
                <Link to="/billing" className={`${styles.nav_link}`}>
                  <img src={BillingIcon} className={`${styles.icon_spacer}`} />
                  Billing
                </Link>
              </li>
              <li className={`${styles.nav_item} ${styles.border_bottom}`}>
                <a
                  href="https://suppport.ntebogangtechnologies.co.bw/"
                  className={`${styles.nav_link}`}
                  target="_blank"
                >
                  <img src={SupportIcon} className={`${styles.icon_spacer}`} />
                  Support
                </a>
              </li>
            </ul>
          </div>
        </nav>
      );
    }

}

export default Sidebar;
