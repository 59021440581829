import React, { Component } from "react";
import styles from "./main_content.module.css";
import MainPanel from "../main_panel/MainPanel";
import Card from "../card/Card";

class MainContent extends Component {
  render() {
    return (
      <MainPanel>
        <div className={`${styles.content_wrapper}`}>{this.props.children}</div>
      </MainPanel>
    );
  }
}

export default MainContent;
