import React, { Component } from "react";
import styles from "./main_panel.module.css";

class MainPanel extends Component {
  render() {
    return <div className={`${styles.main_panel}`}>{this.props.children}</div>;
  }
}

export default MainPanel;
