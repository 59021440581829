const Validation = (username, password
    ) => {
let errors = {}

if(!username){
    errors.username = "Email is required"
}



if(!password){
    errors.password = "Password is required"
}

return errors;

}



export default Validation