import React, { Component } from "react";
import axios from "axios";
import MainContent from "../../components/main_content/MainContent";
import Card from "../../components/card/Card";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import AdminBillingTable from "../../components/table/AdminBillingTable";
import styles from "./billing.module.css";
import dateFormat from "dateformat";
import { useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Invoice from "../../components/invoices/Invoice";
/*import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";*/
import $ from "jquery";
import { Fragment } from "react";
class AdminBilling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            users: [],
            usersData: [],
            totalDue: [],
            invoicesData: [],
            userId: this.props.userId,
        };
        const userGetId = this.props.userId;
    }

    componentDidMount() {
        axios.get("api/logged-in-users").then((response) => {
            console.log("Axios Logged");
            console.log(response);
        });
        const id = 0;
        const userGetId = this.props.userId;
        axios.get("api/logged").then((response) => {});

        fetch(`api/logged-in-users`)
            .then((res) => res.json())
            .then(
                (result) => {
                    const loggedId = result.users.id;

                    //
                    fetch(
                        `https://billingconnapi.kgaswe.ac.bw/api/get/${loggedId}`
                    )
                        .then((res) => res.json())
                        .then(
                            (result) => {
                                //
                                fetch(
                                    `https://billingconnapi.kgaswe.ac.bw/api/get-total/${loggedId}`
                                )
                                    .then((res) => res.json())
                                    .then(
                                        (result) => {
                                            this.setState({
                                                isLoaded: true,
                                                totalDue: result,
                                                userId: loggedId,
                                                mode: "no-cors",
                                            });
                                        },
                                        // Note: it's important to handle errors here
                                        // instead of a catch() block so that we don't swallow
                                        // exceptions from actual bugs in components.
                                        (error) => {
                                            this.setState({
                                                isLoaded: true,
                                                error,
                                            });
                                        }
                                    );

                                this.setState({
                                    isLoaded: true,
                                    usersData: result,
                                    userId: loggedId,
                                });
                            },
                            // Note: it's important to handle errors here
                            // instead of a catch() block so that we don't swallow
                            // exceptions from actual bugs in components.
                            (error) => {
                                this.setState({
                                    isLoaded: true,
                                    error,
                                });
                            }
                        );

                    this.setState({
                        method: "GET",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        isLoaded: true,
                        users: result,
                        userId: loggedId,
                    });
                },

                (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                    });
                }
            );
    }
    render() {
        const {
            error,
            isLoaded,
            users,
            usersData,
            invoicesData,
            id,
            userId,
            totalDue,
        } = this.state;
        const storedUserId = userId;
        const now = new Date();

        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        const convertFirstDay = dateFormat(firstDay, "mmmm dd, yyyy");
        // 👉️ Sat Oct 01 2022 ...

        const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        const convertLastDay = dateFormat(lastDay, "mmmm dd, yyyy");

        // 👉️ Mon Oct 31 2022 ...

        return (
            <MainContent>
                <Card titleName="Billing Invoice">
                    <Breadcrumbs />
                </Card>
                <div>
                    {usersData.map((billData, i) => (
                        <Card className={`${styles.no_card_title}`} key={i}>
                            <div className={`${styles.col_3}`}>
                                <div className={`${styles.col}`}>
                                    <h3
                                        className={`${styles.billing_card_title}`}
                                    >
                                        Account ID
                                    </h3>
                                    <p>{billData.user_id}</p>
                                </div>
                                <div className={`${styles.col}`}>
                                    <h3
                                        className={`${styles.billing_card_title}`}
                                    >
                                        Billing Period
                                    </h3>
                                    <p>
                                        {convertFirstDay} - {convertLastDay}
                                    </p>
                                </div>
                                <div className={`${styles.col}`}>
                                    <h3
                                        className={`${styles.billing_card_title}`}
                                    >
                                        Estimated Payment
                                    </h3>
                                    <p>
                                        BWP{" "}
                                        {totalDue.map((totalData) => (
                                            <Fragment>
                                                {totalData.total_due.toFixed(2)}
                                            </Fragment>
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </Card>
                    ))}
                    <Card titleName="Student Invoice">
                        <AdminBillingTable
                            userId={userId}
                            storedUserId={storedUserId}
                        />

                        <p className={`${styles.text_center}`}>
                            The Very Best in Pre-Primary. Primary and Secondary
                            Education
                        </p>
                    </Card>
                    <div></div>
                </div>
                <Footer />
            </MainContent>
        );
    }
}

export default (props) => <AdminBilling {...props} params={useParams()} />;
